import {
  ActionButton,
  ActionsGroup,
  Alert,
  Button,
  FormattedCurrency,
  FormikEditableInputNumber,
  Icon,
  Modal,
  Panel,
  SectionTitle,
  Table
} from '@elotech/components';
import { History } from 'history';
import React, { useEffect, useState } from 'react';

import { ArtigoFormValue } from '../../../../../types/ArtigoFormValue';
import { AutoInfracaoArtigo } from '../../../../../types/AutoInfracaoArtigo';

type Props = {
  value: ArtigoFormValue;
  deletarArtigoAutoInfracao(id: number): void;
  atualizarFatorMultiplicador(id: number, fator: number): void;
  readonly: boolean;
  onGerarDebitos(): void;
  history: Pick<History, 'push'>;
};

type FooterProps = {
  valor: number;
  disabled: boolean;
  disabledPorDebitosGerados: boolean;
  onGerarDebitos(): void;
};

const Footer: React.FC<FooterProps> = ({
  valor,
  disabled,
  disabledPorDebitosGerados,
  onGerarDebitos
}) => (
  <tfoot>
    <tr>
      <td colSpan={3}>
        <Button
          iconPosition="left"
          onClick={onGerarDebitos}
          disabled={!disabled || disabledPorDebitosGerados}
        >
          <i className={'fa fa-dollar-sign'}></i>Constituir Débitos
        </Button>
      </td>
      <td colSpan={2} className="right hidden-xs">
        <b>Valor Total:</b>
      </td>
      <td className="right">
        <span className="td-content">
          <FormattedCurrency value={valor} />
        </span>
      </td>
    </tr>
  </tfoot>
);

export const EnquadramentoLegalListComponent: React.FC<Props> = ({
  value,
  deletarArtigoAutoInfracao,
  atualizarFatorMultiplicador,
  readonly,
  onGerarDebitos,
  history
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [textoLeiModal, setTextoLeiModal] = useState<string>();
  const [
    disableBotaoConstituirDebito,
    setDisableBotaoConstituirDebito
  ] = useState<boolean>(false);

  useEffect(() => {
    if (!!value) {
      const debitosGerados = value.artigos.filter(
        dg => !!dg.autoInfracaoDebito && !!dg.autoInfracaoDebito.dataEmissao
      );
      if (debitosGerados.length === value.artigos.length) {
        setDisableBotaoConstituirDebito(true);
      }
    }
  }, [value]);

  const onOpenModal = (texto: string) => {
    setTextoLeiModal(texto);
    setShowModal(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  const onClickDelete = (id: number) => {
    Alert.question({
      title: `Deseja realmente excluir esse registro?`
    }).then((result: any) => {
      if (result.value) {
        deletarArtigoAutoInfracao(id);
      }
    });
  };

  return (
    <>
      <SectionTitle marginTop="unset">Artigos Adicionados</SectionTitle>
      <Panel isTable>
        <Table<AutoInfracaoArtigo>
          values={value.artigos}
          keyExtractor={autoInfracaoArtigo => autoInfracaoArtigo.id}
          footer={
            <Footer
              valor={value.valorTotal}
              disabled={readonly}
              disabledPorDebitosGerados={disableBotaoConstituirDebito}
              onGerarDebitos={onGerarDebitos}
            />
          }
        >
          <Table.Column<AutoInfracaoArtigo>
            header="Fundamentação legal"
            value={autoInfracaoArtigo => autoInfracaoArtigo.titulo}
          />
          <Table.Column<AutoInfracaoArtigo>
            header="Texto da lei"
            value={autoInfracaoArtigo =>
              autoInfracaoArtigo.descricao.substr(0, 50) + '...'
            }
          />
          {readonly ? (
            <Table.Column<AutoInfracaoArtigo>
              header="Fator multiplicador"
              value={autoInfracaoArtigo => (
                <FormattedCurrency
                  value={autoInfracaoArtigo.fatorMultiplicador}
                />
              )}
            />
          ) : null}
          {!readonly ? (
            <Table.Column<AutoInfracaoArtigo>
              header="Fator multiplicador"
              value={(auto, index) => (
                <FormikEditableInputNumber
                  name={`artigos.${index}.fatorMultiplicador`}
                  allowNegative={false}
                  validateBeforeChange={value => {
                    if (value > 0) {
                      return true;
                    }
                    Alert.error({
                      title: 'O fator multiplicador deve ser maior que zero.'
                    });
                    return false;
                  }}
                  onConfirmChange={(value: number) => {
                    atualizarFatorMultiplicador(auto.id, value);
                  }}
                />
              )}
            />
          ) : null}
          {/* */}
          <Table.Column<AutoInfracaoArtigo>
            header="Penalidade"
            value={autoInfracaoArtigo =>
              autoInfracaoArtigo.penalidade +
              ' ' +
              (autoInfracaoArtigo.tipo === 'V'
                ? '%'
                : autoInfracaoArtigo.indiceCorrecao.descricao)
            }
          />
          <Table.Column
            header="Débito Gerado"
            value={(item: AutoInfracaoArtigo) =>
              item.idDebito !== undefined && (
                <Icon icon="check-circle" positive />
              )
            }
          />
          <Table.Column<AutoInfracaoArtigo>
            header="Ações"
            value={autoInfracaoArtigo => (
              <>
                <ActionsGroup>
                  <ActionButton
                    icon="university"
                    label="Apuração"
                    disabled={autoInfracaoArtigo.tipo !== 'V'}
                    onClick={() =>
                      history.push(
                        `/enquadramento-legal-apuracao/${autoInfracaoArtigo.id}`
                      )
                    }
                  />
                  <ActionButton
                    icon="trash-alt"
                    label="Remover"
                    disabled={readonly}
                    onClick={() => onClickDelete(autoInfracaoArtigo.id)}
                  />
                  <ActionButton
                    icon="eye"
                    label="Exibir texto da lei"
                    disabled={readonly}
                    onClick={() => onOpenModal(autoInfracaoArtigo.descricao)}
                  />
                </ActionsGroup>
              </>
            )}
          />
        </Table>
        {showModal && (
          <Modal onClose={onCloseModal}>
            <Panel title="Texto da Lei">
              <p
                className="left mb-xs"
                id="teste"
                style={{ marginTop: '10px' }}
              >
                {textoLeiModal}
              </p>
            </Panel>
          </Modal>
        )}
      </Panel>
    </>
  );
};
