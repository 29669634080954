import { ActionButton, Field, Icon, ListPage } from '@elotech/components';
import { History } from 'history';
import React from 'react';

import ProtocoloService from '../../service/ProtocoloService';
import { Protocolo } from '../../types/Protocolo';

type Props = {
  history: Pick<History, 'push'>;
};

const fields: Field[] = [
  {
    label: 'Número do Protocolo',
    name: 'id.numero',
    type: 'NUMBER'
  },
  {
    label: 'Ano',
    name: 'id.ano',
    type: 'NUMBER'
  },
  {
    label: 'Assunto',
    name: 'assunto.descricao',
    type: 'STRING'
  },
  {
    label: 'Nome do Requerente',
    name: 'pessoa.nome',
    type: 'STRING'
  },
  {
    label: 'CNPJ do Requerente',
    name: 'pessoa.cnpjCpf',
    type: 'STRING'
  },
  {
    label: 'Réu',
    name: 'denunciado',
    type: 'STRING'
  }
];

export const GeracaoProcessoImport: React.FC<Props> = ({ history }) => {
  const importar = (item: Protocolo) => {
    history.push(`/processo/protocolo/${item.id}`);
  };

  return (
    <>
      <ListPage<Protocolo>
        title="Importar Protocolo"
        icon="file-invoice"
        baseUrl="/geracao-processo"
        searchFields={fields}
        history={history}
        onSearch={ProtocoloService.load}
        getId={(item: Protocolo) => item.id!}
        hideButtons={['new', 'delete', 'edit', 'view']}
        customRowActions={(item: Protocolo) => [
          !item.solicitacaoProcesso ? (
            <ActionButton
              key="import-button"
              icon="arrow-down"
              label="Importar"
              onClick={() => importar(item)}
            />
          ) : (
            <ActionButton
              key="view-button"
              icon="eye"
              label="Visualizar Geração"
              path={`/processo/${item.solicitacaoProcesso?.processo?.id}/visualizar`}
            />
          )
        ]}
        columns={[
          ['Número/Ano', (item: Protocolo) => `${item.numero}/${item.ano}`],
          ['Assunto', (item: Protocolo) => item.assunto?.descricao],
          ['Requerente', (item: Protocolo) => item.pessoa],
          [
            'Réu',
            (item: Protocolo) => (!!item.denunciado ? item.denunciado : '---')
          ],
          [
            'Já importado?',
            (item: Protocolo) =>
              !!item.solicitacaoProcesso && <Icon icon="check" />
          ]
        ]}
      />
    </>
  );
};
