import { PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { Protocolo } from '../types/Protocolo';

export const BASE_URL: string = `${CONTEXT_PATH}/protocolo`;

const load = (
  search: string,
  page: any
): AxiosPromise<PagedResponse<Protocolo>> =>
  axios.get(BASE_URL, {
    params: { search, ...page }
  });

const findUniqueByUUID = (uuid: any) =>
  axios.get(`${BASE_URL}/buscar-unico-uuid/${uuid}`);

export default { load, findUniqueByUUID };
