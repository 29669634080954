import { FormikCheckBox } from '@elotech/components';
import { FormikProps } from 'formik';
import React from 'react';

import { TipoProcesso } from '../../../types/TipoProcesso';

type Props = {
  formProps: FormikProps<TipoProcesso>;
};

const ConfiguracoesGeral: React.FC<Props> = ({ formProps }) => {
  return (
    <>
      <FormikCheckBox
        label="Denuncia"
        name="denuncia"
        size={1}
        noLabel={false}
        onChange={e => {
          const { checked } = e.target;
          if (checked) {
            formProps.setFieldValue('exigeCadastroImobiliario', false);
            formProps.setFieldValue('vistoriaEmLote', false);
            formProps.setFieldValue('reuNaoObrigatorio', false);
          }
        }}
      />
      <FormikCheckBox
        label="Exige cadastro imobiliário"
        name="exigeCadastroImobiliario"
        size={3}
        noLabel={false}
        fast={false}
        disabled={formProps.values.denuncia}
      />
      <FormikCheckBox
        label="Vistoria em lote"
        name="vistoriaEmLote"
        size={2}
        noLabel={false}
        fast={false}
        disabled={formProps.values.denuncia}
      />
      <FormikCheckBox
        label="Bloqueia criação de atos em Processos em Transferência"
        name="bloqueiaAtosProcessosTransferencia"
        size={3}
        noLabel={false}
      />
      <FormikCheckBox
        label="Réu não obrigatório"
        name="reuNaoObrigatorio"
        size={3}
        noLabel={false}
        fast={false}
        disabled={formProps.values.denuncia}
      />
    </>
  );
};

export { ConfiguracoesGeral };
