import Axios from 'axios';

import { TipoProcesso } from './../types/TipoProcesso.d';
import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';

const crudService = CrudService<TipoProcesso>('/tipo-processo');

const defaultSort = { sort: 'id' };

const loadVo = (searchParams: string, page?: any) =>
  Axios.get(`${CONTEXT_PATH}/tipo-processo`, {
    params: {
      search: searchParams,
      ...page,
      fields: 'id,descricao'
    }
  });

const getDenuncias = (search?: string, page?: any) =>
  Axios.get(`${CONTEXT_PATH}/tipo-processo/denuncias`, {
    params: {
      search: search,
      ...defaultSort,
      ...page
    }
  });

export default { ...crudService, loadVo, getDenuncias };
