import {
  ActionButton,
  ActionsGroup,
  Alert,
  FAB,
  FabSpeedDial,
  Field,
  Icon,
  Loading,
  Panel,
  SearchFilter,
  SearchPagination,
  Table,
  usePagedQuery
} from '@elotech/components';
import { History } from 'history';
import React from 'react';

import { situacoesGeracaoProcesso } from '../../enum/GeracaoSolicitacaoProcesso';
import { getDescricaoSituacaoProcessoProtocolo } from '../../enum/SituacaoProcessoProtocolo';
import GeracaoProcessoService from '../../service/GeracaoProcessoService';
import { GeracaoProcesso } from '../../types/GeracaoProcesso';

type Props = {
  history: Pick<History, 'push'>;
};

const fields: Field[] = [
  {
    label: 'Número do Protocolo',
    name: 'numeroProtocolo',
    type: 'STRING'
  },
  {
    label: 'Situação',
    name: 'situacao',
    type: 'ENUM',
    options: situacoesGeracaoProcesso.map(situacao => ({
      name: `${situacao.codigo}`,
      descricao: situacao.descricao
    }))
  },
  {
    label: 'Assunto',
    name: 'processo.assunto.descricao',
    type: 'STRING'
  },
  {
    label: 'Fiscal Responsável',
    name: 'fiscalResponsavel.nome',
    type: 'STRING'
  }
];

export const GeracaoProcessoProtocoloTable: React.FC<Props> = ({ history }) => {
  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<GeracaoProcesso>({
    search: GeracaoProcessoService.load,
    onError: error => {
      Alert.error({ title: 'Erro ao buscar Processos Protocolo.' }, error);
    }
  });

  return (
    <>
      <Panel isTable>
        <Loading loading={loading} />
        <SearchFilter fields={fields} search={doSearch} />
        <Table loading={loading} values={values}>
          <Table.Column<GeracaoProcesso>
            header="Id"
            value={item => item.id}
          ></Table.Column>
          <Table.Column<GeracaoProcesso>
            header="Número do Protocolo"
            value={item => item.numeroProtocolo || '---'}
          ></Table.Column>
          <Table.Column<GeracaoProcesso>
            header="Assunto"
            value={item => item.processo?.assunto?.descricao || '---'}
          ></Table.Column>
          <Table.Column<GeracaoProcesso>
            header="Fiscal Responsável"
            value={item => item.fiscalResponsavel?.nome || '---'}
          ></Table.Column>
          <Table.Column<GeracaoProcesso>
            header="Situação"
            value={item => getDescricaoSituacaoProcessoProtocolo(item.situacao)}
          ></Table.Column>
          <Table.Column<GeracaoProcesso>
            header="Gerou Processo"
            value={item => item.processo && <Icon icon="check" />}
          ></Table.Column>
          <Table.Column<GeracaoProcesso>
            header=""
            name="actions-buttons"
            value={item => (
              <ActionsGroup>
                {item.processo && (
                  <ActionButton
                    key="view-button"
                    icon="eye"
                    label="Visualizar"
                    path={`/processo/${item.processo?.id}/visualizar`}
                  />
                )}
              </ActionsGroup>
            )}
          ></Table.Column>
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
      <div className="btn-save">
        <FabSpeedDial icon="ellipsis-v" title="Ações">
          <FAB
            icon="file-import"
            iconColor="white"
            title="Importar"
            onClick={() => history.push('/geracao-processo-importar')}
          />
        </FabSpeedDial>
      </div>
    </>
  );
};
