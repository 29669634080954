import { GeracaoProcessoAlvaraImport } from '../pages/geracao-processo/geracao-processo-alvara/GeracaoProcessAlvaraImport';
import { GeracaoProcessoAlvaraDataPage } from '../pages/geracao-processo/geracao-processo-alvara/GeracaoProcessoAlvaraDataPage';
import { GeracaoProcessoImport } from '../pages/geracao-processo/GeracaoProcessoImport';
import { GeracaoProcessoListPage } from '../pages/geracao-processo/GeracaoProcessoListPage';
import { Roles } from '../utils/Roles';

const geracaoProcessosRoutes = [
  {
    title: 'Gerador de Processos',
    path: '/geracao-processo',
    component: GeracaoProcessoListPage,
    role: Roles.geracao_processo_read.name
  },
  {
    path: '/geracao-processo-alvara/:id/visualizar',
    component: GeracaoProcessoAlvaraDataPage,
    title: 'Gerador de Processos Alvará',
    role: Roles.geracao_processo_read.name
  },
  {
    path: '/geracao-processo-alvara/:id/visualizar',
    parent: '/geracao-processo',
    component: GeracaoProcessoAlvaraDataPage,
    title: 'Gerador de Processos Alvará',
    role: Roles.geracao_processo_read.name
  },
  {
    title: 'Importar',
    path: '/geracao-processo-alvara/importar',
    component: GeracaoProcessoAlvaraImport,
    role: Roles.geracao_processo_update.name
  },
  {
    title: 'Importar',
    path: '/geracao-processo-alvara/importar',
    component: GeracaoProcessoAlvaraImport,
    role: Roles.geracao_processo_update.name,
    parent: '/geracao-processo'
  },
  {
    title: 'Importar',
    path: '/geracao-processo-importar',
    component: GeracaoProcessoImport,
    role: Roles.geracao_processo_update.name
  }
];

export default geracaoProcessosRoutes;
