import {
  Alert,
  BasicInput,
  Button,
  Container,
  FormikAutocomplete,
  FormikInputDate,
  FormikSelect,
  Loading,
  Row,
  Yup
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import React, { useEffect, useState } from 'react';

import AtoProcessoService from '../../../service/AtoProcessoService';
import TipoProcessoAtoService from '../../../service/TipoProcessoAtoService';
import UsuarioService from '../../../service/UsuarioService';
import { AtoProcesso } from '../../../types/AtoProcesso';
import { AtoVo } from '../../../types/AtoVo';
import { Processo } from '../../../types/Processo';
import { TipoAto } from '../../../types/TipoAto';
import { TipoAtoConfiguracao } from '../../../types/TipoAtoConfiguracao';
import { TipoProcessoAto } from '../../../types/TipoProcessoAto';
import { Usuario } from '../../../types/Usuario';
import Constantes from '../../../utils/Constantes';

type Props = {
  atos: AtoProcesso[];
  processo: Processo;
  history: Pick<History, 'push'>;
};

export const NewAtoPage: React.FC<Props> = ({ atos, processo, history }) => {
  const [loadingAtosPVinculacao, setLoadingAtosPVinculacao] = useState(false);
  const [
    loadingAtosPVinculacaoAutoInfracao,
    setLoadingAtosPVinculacaoAutoInfracao
  ] = useState(false);
  const [loading, setLoading] = useState(false);

  const [
    atosDoProcessoParaVinculacao,
    setAtosDoProcessoParaVinculacao
  ] = useState<AtoProcesso[]>([]);

  const [
    atosDoProcessoParaVinculacaoAutoInfracao,
    setAtosDoProcessoParaVinculacaoAutoInfracao
  ] = useState<AtoProcesso[]>([]);

  const [tiposDeAtoDoProcesso, setTiposDeAtoDoProcesso] = useState<
    TipoProcessoAto[]
  >([]);

  const [tipoAtoSelecionado, setTipoAtoSelecionado] = useState<TipoAto>(
    {} as TipoAto
  );

  const loadTiposDeAtoDoProcesso = (processoSelecionado: Processo) => {
    setLoading(true);
    let idTipoProcesso = processoSelecionado.tipoProcesso?.id;
    if (!idTipoProcesso) {
      return;
    }
    TipoProcessoAtoService.loadTiposAtosAtivos(idTipoProcesso)
      .then((result: AxiosResponse<TipoProcessoAto[]>) => {
        setTiposDeAtoDoProcesso(result.data);
      })
      .catch((error: any) => {
        Alert.error(
          { title: `Erro ao carregar tipos de Ato do Processo.` },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const loadAtosDoProcessoParaVinculacao = (processoSelecionado: Processo) => {
    setLoadingAtosPVinculacao(true);
    AtoProcessoService.findAtosDoProcessoParaVinculacao(processoSelecionado.id)
      .then((result: AxiosResponse<AtoProcesso[]>) =>
        setAtosDoProcessoParaVinculacao(result.data)
      )
      .catch((error: any) => {
        Alert.error(
          { title: `Erro ao carregar os atos para vinculação do Processo.` },
          error
        );
      })
      .finally(() => setLoadingAtosPVinculacao(false));
  };

  const loadAtosDoProcessoParaVinculacaoAutoInfracao = (
    processoSelecionado: Processo
  ) => {
    setLoadingAtosPVinculacaoAutoInfracao(true);
    AtoProcessoService.findAtosAutoDeInfracaoDoProcessoParaVinculacao(
      processoSelecionado.id
    )
      .then((result: AxiosResponse<AtoProcesso[]>) =>
        setAtosDoProcessoParaVinculacaoAutoInfracao(result.data)
      )
      .catch((error: any) => {
        Alert.error(
          {
            title: `Erro ao carregar os autos de infração para vinculação do Processo.`
          },
          error
        );
      })
      .finally(() => setLoadingAtosPVinculacaoAutoInfracao(false));
  };

  const verificaAto = (atoVo: AtoVo) => {
    setLoading(true);
    AtoProcessoService.existTipoProcessoAtoCadastro(
      +processo.idCadastroReu!,
      atoVo.idTipoAtoProcesso!
    )
      .then(response => {
        setLoading(false);
        if (response.data.id !== 0) {
          Alert.question({
            title: `Atenção! Já existe um ato semelhante para este cadastro vinculado ao processo ${response.data.processo}/${response.data.exercicio}, deseja criar um novo ato mesmo assim?`
          }).then((result: any) => {
            if (result.value) {
              criarNovoAto(atoVo);
            }
          });
        } else {
          criarNovoAto(atoVo);
        }
      })
      .catch((error: any) =>
        Alert.error(
          {
            title:
              'Ocorreu um erro ao verificar existência de processo do mesmo cadastro'
          },
          error
        )
      );
  };

  const criarNovoAto = (atoVo: AtoVo) => {
    setLoading(true);
    atoVo.idProcesso = processo.id;
    AtoProcessoService.criarAto(atoVo)
      .then(response => {
        history.push(
          `/processo/${response.data.idProcesso}/processo-ato/${response.data.id}`
        );
      })
      .catch(response => {
        Alert.error(
          { title: 'Não foi possível adicionar um novo ato.' },
          response
        );
      })
      .finally(() => setLoading(false));
  };

  function isResposta(conf: TipoAtoConfiguracao) {
    return conf.acao === Constantes.acaoResposta;
  }

  function isCancelarAto(conf: TipoAtoConfiguracao) {
    return conf.acao === Constantes.acaoCancelarAto;
  }

  function isEncaminhado(conf: TipoAtoConfiguracao) {
    return conf.acao === Constantes.acaoEncaminhado;
  }

  function isRenderAtoVinculado(conf: TipoAtoConfiguracao) {
    return (
      conf.acao === Constantes.acaoNotificacao ||
      conf.acao === Constantes.acaoDefesaFiscal
    );
  }

  const emRespostaA = () => {
    return tipoAtoSelecionado?.configuracoes?.some(isResposta);
  };

  const cancelarAto = () =>
    tipoAtoSelecionado?.configuracoes?.some(isCancelarAto);

  const inicioPrazo = () =>
    tipoAtoSelecionado?.configuracoes?.some(isResposta) ||
    tipoAtoSelecionado?.prazo! > 0;

  const atoVinculado = () =>
    tipoAtoSelecionado?.configuracoes?.some(isRenderAtoVinculado);
  const encaminhado = () =>
    tipoAtoSelecionado?.configuracoes?.some(isEncaminhado);
  const atoVinculadoAutoInfracao = () =>
    tipoAtoSelecionado?.configuracoes?.some(isRenderAtoVinculadoAutoDeInfracao);

  const validationSchema = (atosDoProcessoParaVinculacao: AtoProcesso[]) => {
    const usuarioDestino: any = encaminhado()
      ? {
          usuarioDestino: Yup.string()
            .required()
            .label('Usuário Destino')
        }
      : {};
    const atoVinculadoV: any =
      atoVinculado() ||
      cancelarAto() ||
      emRespostaA() ||
      atoVinculadoAutoInfracao()
        ? {
            atoVinculado: Yup.number()
              .required()
              .label('Ato vinculado')
              .test(
                'atoOrigemAberto',
                'O ato a ser vinculado não pode estar aberto.',
                function(value: string): boolean {
                  const idAtoVinculado = +value;
                  const atoVinculado = atosDoProcessoParaVinculacao
                    .concat(atosDoProcessoParaVinculacaoAutoInfracao)
                    .find(ato => ato.id === idAtoVinculado);
                  return atoVinculado?.idStatus !== 'A';
                }
              )
          }
        : {};
    const inicioPrazoV: any = inicioPrazo()
      ? {
          inicioPrazo: Yup.date()
            .required()
            .label('Inicio prazo')
        }
      : {};
    return Yup.object().shape({
      idTipoAtoProcesso: Yup.number()
        .required()
        .label('tipo ato'),
      ...usuarioDestino,
      ...atoVinculadoV,
      ...inicioPrazoV
    });
  };

  function isRenderAtoVinculadoAutoDeInfracao(conf: TipoAtoConfiguracao) {
    return conf.acao === Constantes.acaoNotificacaoReincidencia;
  }

  useEffect(() => {
    loadAtosDoProcessoParaVinculacao(processo);
    loadTiposDeAtoDoProcesso(processo);
    loadAtosDoProcessoParaVinculacaoAutoInfracao(processo);
  }, [processo]);

  return (
    <Container title="Novo Ato" icon="university">
      <Loading loading={loading} />
      <Formik
        enableReinitialize
        initialValues={{}}
        onSubmit={values => {
          verificaAto(values);
        }}
        validationSchema={() => validationSchema(atosDoProcessoParaVinculacao)}
        render={(formProps: FormikProps<AtoVo>) => (
          <>
            <Row>
              <FormikSelect<TipoProcessoAto>
                name="idTipoAtoProcesso"
                label="Tipo do Ato"
                options={tiposDeAtoDoProcesso}
                getOptionLabel={value => `${value.tipoAto.descricao}`}
                getOptionValue={value => value.id}
                onSelect={value => {
                  setTipoAtoSelecionado(value.tipoAto);
                }}
                size={6}
                fast={false}
              />
              {emRespostaA() && (
                <FormikSelect<AtoProcesso>
                  name="atoVinculado"
                  options={atos}
                  label="Em resposta a"
                  getOptionLabel={option =>
                    `${option.tipoAto?.descricao} - Nr. ${option.numero}`
                  }
                  getOptionValue={option => option.id}
                  fast={false}
                  size={4}
                />
              )}
            </Row>
            <Row>
              {cancelarAto() && (
                <FormikSelect<AtoProcesso>
                  name="atoVinculado"
                  options={atos}
                  label="Cancelar Ato"
                  getOptionLabel={option =>
                    `${option.tipoAto?.descricao} - Nr. ${option.numero}`
                  }
                  getOptionValue={option => option.id}
                  fast={false}
                  size={4}
                />
              )}
              {inicioPrazo() && (
                <FormikInputDate
                  label="Início Prazo"
                  name="inicioPrazo"
                  size={2}
                />
              )}
              {atoVinculado() &&
                (loadingAtosPVinculacao ? (
                  <BasicInput
                    name="atoVinculado"
                    label="Ato Vinculado"
                    render={() => <p>Carregando...</p>}
                  />
                ) : (
                  <FormikSelect<AtoProcesso>
                    name="atoVinculado"
                    options={atosDoProcessoParaVinculacao}
                    label="Ato Vinculado"
                    getOptionLabel={option =>
                      `${option.tipoAto?.descricao} - Nr. ${option.numero}`
                    }
                    getOptionValue={option => option.id}
                    fast={false}
                    size={4}
                  />
                ))}
              {atoVinculadoAutoInfracao() &&
                (loadingAtosPVinculacaoAutoInfracao ? (
                  <BasicInput
                    name="atoVinculado"
                    label="Ato Vinculado"
                    render={() => <p>Carregando...</p>}
                  />
                ) : (
                  <FormikSelect<AtoProcesso>
                    name="atoVinculado"
                    options={atosDoProcessoParaVinculacaoAutoInfracao}
                    label="Ato Vinculado"
                    getOptionLabel={option =>
                      `${option.tipoAto?.descricao} - Nr. ${option.numero}`
                    }
                    getOptionValue={option => option.id}
                    fast={false}
                    size={4}
                  />
                ))}
              {encaminhado() && (
                <FormikAutocomplete<Usuario>
                  name="usuarioDestino"
                  label="Usuário Destino"
                  onSearch={UsuarioService.usuarioAutoComplete}
                  getOptionLabel={value => `${value.nome}`}
                  getOptionValue={value => value.id}
                  onItemSelected={(formProps, value) => {
                    formProps.setFieldValue('usuarioDestino', value);
                  }}
                  size={6}
                  fast={false}
                />
              )}
            </Row>
            <br />
            <Button type="submit" onClick={formProps.submitForm}>
              Salvar
            </Button>
          </>
        )}
      />
    </Container>
  );
};
