import { PageRequest } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import {
  GerarProcessoProtocoloDTO,
  Processo,
  ProcessoAlvara,
  ProcessoGeracaoDTO,
  ProcessoLoteDTO,
  ProcessoSolicitacaoBaixa
} from './../types/Processo';
import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';
import { ProcessoExistente } from '../types/ProcessoExistente';

const crudService = CrudService<Processo>('/processo', {
  sort: 'id,desc'
});

const verificarExisteProcessoParaReu = (
  entity: ProcessoGeracaoDTO
): AxiosPromise<ProcessoExistente> =>
  axios.post(`${CONTEXT_PATH}/processo/verificar-processo-existente/`, entity);

const verificarExisteProcessoParaReus = (
  entity: Processo
): AxiosPromise<ProcessoExistente> =>
  axios.post(
    `${CONTEXT_PATH}/processo/verificar-processo-existente-alvara/`,
    entity
  );

const quantidadeProcessosAbertos = () =>
  axios.get(`${CONTEXT_PATH}/processo/quantidade-processos-abertos/`);

const quantidadeMeusProcessosAbertos = (cpfUsuario: string) =>
  axios.get(
    `${CONTEXT_PATH}/processo/meus-atos-processos-prazos-encerrados/${cpfUsuario}`
  );

const quantidadeProcessosAguardandoMeuAceite = (cpfUsuario: string) =>
  axios.get(
    `${CONTEXT_PATH}/processo/processos-aguardando-meu-aceite/${cpfUsuario}`
  );

const quantidadeProcessosComAtosComPrazoEncerrado = () =>
  axios.get(
    `${CONTEXT_PATH}/processo/quantidade-processos-com-atos-com-prazo-encerrado/`
  );

const processosComAtosComPrazoEncerrado = (
  search: any,
  pagination?: PageRequest
) =>
  axios.get(
    `${CONTEXT_PATH}/processo/processos-com-atos-com-prazo-encerrado/`,
    {
      params: { ...pagination, search: search }
    }
  );

const validarPermissaoProcesso = (id: number | string) =>
  axios.get(`${CONTEXT_PATH}/processo/validar/${id}`);

const alterarNumeroProtocolo = (
  id: number,
  numeroProtocolo: any
): AxiosPromise<any> =>
  axios.post(
    `${CONTEXT_PATH}/processo/alterar-numero-protocolo/${id}`,
    numeroProtocolo
  );

const alterarPeriodoProcesso = (id: number, periodo: any): AxiosPromise<any> =>
  axios.post(
    `${CONTEXT_PATH}/processo/alterar-periodo-fiscalizacao/${id}`,
    periodo
  );

const alterarReu = (id: number, reu: any, tipoReu: string): AxiosPromise<any> =>
  axios.post(`${CONTEXT_PATH}/processo/alterar-reu-${tipoReu}/${id}`, reu);

const receberProcesso = (id: number): AxiosPromise<any> =>
  axios.post(`${CONTEXT_PATH}/processo/receber-processo/${id}`);

const reabrirProcesso = (id: number, observacao: any): AxiosPromise<any> =>
  axios.post(`${CONTEXT_PATH}/processo/reabrir-processo/${id}`, observacao);

const saveProcessoAlvara = (processo: ProcessoAlvara): AxiosPromise<any[]> =>
  axios.post(`${CONTEXT_PATH}/processo/processo-alvara`, processo);

const saveProcessoSolicitacaoBaixa = (
  processo: ProcessoSolicitacaoBaixa
): AxiosPromise<any[]> =>
  axios.post(`${CONTEXT_PATH}/processo/processo-solicitacao-baixa`, processo);

const saveProcessoLote = (processo: ProcessoLoteDTO): AxiosPromise<any[]> =>
  axios.post(`${CONTEXT_PATH}/processo/processo-lote`, processo);

const exportPlanilhaNotificacaoVencida = () =>
  axios.get(`${CONTEXT_PATH}/processo/exportar-notificacao-vencida-xls`);

const saveProcessoProtocolo = (processo: GerarProcessoProtocoloDTO) =>
  axios.post(`${CONTEXT_PATH}/processo/salvar-processo-protocolo`, processo);

const saveProcesso = (processo: GerarProcessoProtocoloDTO) =>
  axios.post(`${CONTEXT_PATH}/processo/salvar-processo`, processo);

const getProcessoFull = (id: number) =>
  axios.get(`${CONTEXT_PATH}/processo/buscar/${id}`);

export default {
  ...crudService,
  verificarExisteProcessoParaReu,
  quantidadeProcessosAbertos,
  quantidadeMeusProcessosAbertos,
  quantidadeProcessosAguardandoMeuAceite,
  quantidadeProcessosComAtosComPrazoEncerrado,
  processosComAtosComPrazoEncerrado,
  validarPermissaoProcesso,
  alterarNumeroProtocolo,
  alterarPeriodoProcesso,
  receberProcesso,
  reabrirProcesso,
  saveProcessoAlvara,
  saveProcessoSolicitacaoBaixa,
  verificarExisteProcessoParaReus,
  saveProcessoLote,
  exportPlanilhaNotificacaoVencida,
  alterarReu,
  saveProcessoProtocolo,
  getProcessoFull,
  saveProcesso
};
