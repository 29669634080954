import {
  AutoComplete,
  Col,
  FormikAutocomplete,
  Row,
  Yup
} from '@elotech/components';
import { AxiosPromise } from 'axios';
import { Formik, FormikProps } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';

import {
  JsonikData,
  JsonikFieldDef,
  JsonikFieldMap,
  JsonikRecipe
} from '../common/types';
import { normalizeRecipe } from '../editor/utils';
import { baseFieldMap } from './BaseFields';
import { mixBaseFieldMap } from './utils';

type Props = {
  recipe: JsonikRecipe;
  data?: JsonikData;
  render?: (props: FormikProps<object | undefined>) => ReactNode;
  onSubmit?: (values: any) => void;
  customFields?: JsonikFieldMap[];
  allFields?: any;
};

export const JsonikForm: React.FC<Props> = ({
  recipe,
  data,
  render,
  onSubmit,
  customFields,
  allFields
}) => {
  const [fields, setFields] = useState<JsonikFieldDef[]>([]);
  const [error, setError] = useState<String | undefined>();
  const [initialValues, setInitialValues] = useState<object | undefined>();
  const [internalFieldMap, setFieldMap] = useState<JsonikFieldMap[]>(
    baseFieldMap
  );

  /**
   * Parse do JSON de definição de campos (receita).
   */
  useEffect(() => {
    if (!recipe) {
      return;
    }

    try {
      setFields(normalizeRecipe(recipe));
    } catch (e) {
      setError(`JSON de receita inválido: ${e}`);
      return;
    }
  }, [recipe]);

  /**
   * Parse do JSON de valores dos campos.
   */
  useEffect(() => {
    try {
      setInitialValues(data || undefined);
    } catch (e) {
      setError(`JSON de dados inválido: ${e}`);
      return;
    }
  }, [data]);

  /**
   * Mistura o customFields passado via props com o fieldMap padrão, podendo
   * substituir definições de campos.
   */
  useEffect(() => {
    setFieldMap(mixBaseFieldMap(customFields)!);
  }, [customFields]);

  const getMap = (type: string) => {
    return internalFieldMap.find(map => map.type === type);
  };

  const makeValidationSchema = () => {
    const shape = {};

    fields.forEach(field => {
      let yupFunc = getMap(field.type)?.yupFunc;
      let yupType = getMap(field.type)?.yupType;

      if (!yupType && field.type === 'autocompletetypefield') {
        yupType = Yup.object;
      }

      if (!!yupFunc) {
        shape[field.name] = yupFunc(field);
      } else if (!!yupType) {
        shape[field.name] = yupType().label(field.label);

        if (field.required) {
          shape[field.name] = shape[field.name].required();
        }
      }
    });

    //TODO: Yup.object().shape({...shape, ...customValidation});
    return Yup.object()
      .shape(shape)
      .test('', '', function(formulario) {
        if (formulario.props !== undefined) {
          let typesValidation = ['select', 'autocomplete', 'set'];
          if (
            typesValidation.indexOf(formulario?.typeField?.type) > -1 &&
            formulario.props?.options?.length < 2
          ) {
            return this.createError({
              path: 'props',
              message: 'Deve ter pelo menos duas opcoes'
            });
          }
        }
        return true;
      })
      .test('', '', function(formulario) {
        if (formulario.name !== undefined) {
          if (typeof allFields === 'string') {
            allFields = JSON.parse(allFields);
          }
          const dataFieldEdit: any = data;
          const field = allFields.find(
            (field: any) => field.name === formulario.name
          );

          if (field && dataFieldEdit.name !== field.name) {
            return this.createError({
              path: 'name',
              message: 'Nome já existe na tabela'
            });
          }
        }
        return true;
      });
  };

  const size = (dField: JsonikFieldDef) =>
    dField.size === 'full' ? 12 : +dField.size! || 2;

  /**
   * Cria um <Row> para os campos que somem 12 no size. Isto evita que a mensagem de
   * erro do BasicInput quebre o layout.
   */
  const makeFieldRows = () => {
    const rows: any[][] = [[]];
    fields.forEach((field, index, array) => {
      const lastRow = rows[rows.length - 1];
      lastRow.push(field);

      const rowSize = lastRow.reduce(
        (p, c) => parseInt(p) + parseInt(c.size),
        0
      );
      const isLastField = index === array.length - 1;

      if (!isLastField && (rowSize >= 12 || field.break)) rows.push([]);
    }, []);

    return rows;
  };

  const makeFields = () => {
    if (!fields || !fields.length) return null;

    return makeFieldRows().map(
      (row, index) => row.length && <Row key={index}>{row.map(makeField)}</Row>
    );
  };

  const constTypes = () => {
    return Promise.resolve({ data: internalFieldMap }) as AxiosPromise;
  };

  const makeField = (dField: JsonikFieldDef) => {
    const field = internalFieldMap.find(
      fm => fm.type.toUpperCase() === dField.type.toUpperCase()
    );
    if (dField.type === 'autocompletetypefield') {
      return (
        <FormikAutocomplete
          key="autocompletetypefield"
          name="typeField"
          label="Tipo do Campo"
          onSearch={constTypes}
          onItemSelected={async (form: any, item: any) => {
            form.setFieldValue('type', item?.type);
            if (item?.getDefaultProps) {
              form.setFieldValue('props', item.getDefaultProps());
            }
          }}
          getDetailOptionLabel={option => (
            <AutoComplete.Item title={option.friendlyName.toUpperCase()}>
              {option.description}
            </AutoComplete.Item>
          )}
          getOptionLabel={tipo => {
            return tipo.friendlyName;
          }}
          getOptionValue={(tipo: any) => tipo.description}
          size={4}
        />
      );
    }

    if (!field) return null;
    return (
      <span key={dField.name}>
        <field.component
          key={dField.name}
          name={dField.name}
          label={dField.label || dField.name}
          hint={dField.hint}
          props={dField.props}
          size={size(dField)}
          disabled={false}
        />
        {dField.break ? <Col md={12} style={{ height: '10px' }} /> : null}
      </span>
    );
  };

  if (!!error) {
    return <div>{error}</div>;
  }

  if (!recipe) {
    return <div data-testid="recipenull"></div>;
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (values: any) => {
        onSubmit && onSubmit(values);
      }}
      validateOnChange
      validationSchema={() => makeValidationSchema()}
      render={(props: FormikProps<object | undefined>) => (
        <>
          <div style={{ marginBottom: '5px' }}>{makeFields()}</div>

          <div style={{ marginBottom: '5px' }}>{render && render(props)}</div>
        </>
      )}
    />
  );
};
