import {
  BasicInput,
  DataPage,
  FormikCheckBox,
  Panel,
  Row,
  Yup
} from '@elotech/components';
import { FormikProps } from 'formik';
import { History } from 'history';
import { Alert } from 'iss-common/utils';
import React, { useState } from 'react';
import { match } from 'react-router-dom';

import TipoProcessoService from '../../../service/TipoProcessoService';
import { TipoProcesso } from '../../../types/TipoProcesso';
import { TipoAtoComponent } from '../tipo-processo/TipoAtoComponent';
import { ConfiguracoesAlvara } from './ConfiguracoesAlvara';
import { ConfiguracoesEndereco } from './ConfiguracoesEndereco';
import { ConfiguracoesGeral } from './ConfiguracoesGeral';
import { ConfiguracoesHabitese } from './ConfiguracoesHabitese';
import { TipoAtoProcesso } from './TipoAtoProcesso';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
};

const SCHEMA_VALIDATION = Yup.object().shape({
  descricao: Yup.string()
    .required()
    .min(3, 'A descrição deve ter no mínimo 3 caracteres')
    .max(100, 'A descrição deve ter no máximo 100 caracteres')
    .label('Descrição'),
  tiposProcessoAto: Yup.array()
    .required()
    .min(0, 'Pelo menos um tipo de ato no processo ')
    .label('Pelo menos um tipo de ato no processo '),
  prazoDoProcesso: Yup.string()
    .required()
    .max(5, 'Prazo maximo deve conter no maximo 5 caracteres')
    .label('Prazo do Processo')
});

export const TipoProcessoDataPage: React.FC<Props> = ({ match, history }) => {
  const onLoad = (id: number) => TipoProcessoService.findById(id);

  const onSave = (entity: TipoProcesso) => TipoProcessoService.save(entity);
  const [indiceEdicao, setIndiceEdicao] = useState<number>();

  return (
    <DataPage<TipoProcesso>
      load={onLoad}
      match={match}
      name="tipoato"
      title="Tipo de Processo"
      history={history}
      validationSchema={SCHEMA_VALIDATION}
      initialValues={{ tiposProcessoAto: [], ativo: true }}
      redirectUrl="/configuracao/tipo-processo"
      onSave={onSave}
      icon="university"
      render={(formProps: FormikProps<TipoProcesso>) => (
        <>
          <Row>
            <BasicInput label="Código" disabled={true} name="id" size={1} />
            <BasicInput
              label="Descrição"
              name="descricao"
              maxLength={100}
              size={10}
            />
            <FormikCheckBox
              label="Ativo"
              name="ativo"
              size={1}
              noLabel={false}
            />
          </Row>
          <Panel
            title="Tipos de Ato do Processo"
            className="mt-xs"
            expansible={true}
          >
            <TipoAtoComponent
              initialValue={
                indiceEdicao === undefined
                  ? undefined
                  : formProps.values.tiposProcessoAto[indiceEdicao]
              }
              onSubmit={(valor: any) => {
                const item = formProps.values.tiposProcessoAto.find(
                  item => item.tipoAto.id === valor.tipoAto.id
                );
                if (item) {
                  Alert.warning({ title: 'Registro já incluido' });
                  return;
                }
                if (indiceEdicao !== undefined) {
                  formProps.values.tiposProcessoAto[indiceEdicao] = valor;
                  formProps.setFieldValue(
                    'tiposProcessoAto',
                    formProps.values.tiposProcessoAto
                  );
                } else {
                  formProps.setFieldValue('tiposProcessoAto', [
                    ...formProps.values.tiposProcessoAto,
                    valor
                  ]);
                }
                setIndiceEdicao(undefined);
              }}
            />

            <TipoAtoProcesso
              onDelete={index => {
                const tiposProcessoAto = [
                  ...formProps.values.tiposProcessoAto.slice(0, index),
                  ...formProps.values.tiposProcessoAto.slice(index + 1)
                ];
                formProps.setFieldValue('tiposProcessoAto', tiposProcessoAto);
              }}
              tiposProcessoAto={formProps.values.tiposProcessoAto}
              onEdit={index => {
                setIndiceEdicao(index);
              }}
            />
          </Panel>
          <Panel title="Configurações Gerais" expansible={true}>
            <ConfiguracoesGeral formProps={formProps} />
          </Panel>
          <Panel title="Configurações Habite-se" expansible={true}>
            <ConfiguracoesHabitese />
          </Panel>
          <Panel title="Configurações Alvará" expansible={true}>
            <ConfiguracoesAlvara />
          </Panel>
          <Panel title="Configurações Endereço" expansible={true}>
            <ConfiguracoesEndereco />
          </Panel>
        </>
      )}
    />
  );
};
