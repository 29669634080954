import { BreadCrumbRoute } from '@elotech/components';

import { SolicitacaoBaixaRedeSimListPage } from '../pages/alvara/solicitacao-baixa-redesim/SolicitacaoBaixaRedeSimListPage';
import { ApuracaoAnualDataPage } from '../pages/processos/processo-detalhe/ApuracaoAnual/ApuracaoAnualDataPage';
import { ApuracaoMensalDataPage } from '../pages/processos/processo-detalhe/ApuracaoMensal/ApuracaoMensalDataPage';
import { ApuracaoNotaFiscalPage } from '../pages/processos/processo-detalhe/ApuracaoMensal/ApuracaoNotaFiscalPage';
import { ApuracaoOutrasReceitasListPage } from '../pages/processos/processo-detalhe/ApuracaoMensal/ApuracaoOutrasReceitasListPage';
import AtoDataPage from '../pages/processos/processo-detalhe/AtoDataPage';
import { EnquadramentoLegalApuracoesComponent } from '../pages/processos/processo-detalhe/formularios-estaticos/EnquadramentoLegal/EnquadramentoLegalApuracoesComponent';
import { ProcessoDetailPage } from '../pages/processos/processo-detalhe/ProcessoDetailPage';
import { ProcessoDataPage } from '../pages/processos/ProcessoDataPage';
import { ProcessosListPage } from '../pages/processos/ProcessosListPage';
import { Roles } from '../utils/Roles';

const processosRoutes: BreadCrumbRoute[] = [
  {
    path: '/processo',
    component: ProcessosListPage,
    title: 'Processos',
    role: Roles.processo_read.name,
    icon: 'layer-group'
  },
  {
    path: '/processo/:id',
    parent: '/processo',
    component: ProcessoDataPage,
    title: 'Cadastro de Processo',
    role: Roles.processo_update.name,
    icon: 'cash-register'
  },
  {
    path: '/processo/novo',
    parent: '/processo',
    component: ProcessoDataPage,
    title: 'Cadastro de Processo',
    role: Roles.processo_update.name,
    icon: 'cash-register'
  },
  {
    path: '/processo/divergencia',
    parent: '/processo',
    component: ProcessoDataPage,
    title: 'Cadastro de Processo',
    role: Roles.processo_update.name,
    icon: 'cash-register'
  },
  {
    path: '/processo/:id/visualizar',
    parent: '/processo',
    component: ProcessoDetailPage,
    title: 'Processo',
    role: Roles.processo_read.name,
    icon: 'university'
  },
  {
    path: '/processo/geracao-processo/:id',
    component: ProcessoDataPage,
    title: 'Processo Gerador Processo',
    role: Roles.processo_update.name,
    icon: 'cash-register'
  },
  {
    path: '/processo/protocolo/:id',
    component: ProcessoDataPage,
    title: 'Processo',
    role: Roles.processo_update.name,
    icon: 'file-invoice'
  },
  {
    path: '/processo/:id/processo-ato/:id',
    parent: '/processo/:id/visualizar',
    parentURLBuilder: currentRoute =>
      `${currentRoute.matchedURL!.match(/\/processo\/\d+/)}/visualizar`,
    component: AtoDataPage,
    title: 'Processo Ato',
    role: Roles.processo_update.name,
    icon: 'file-signature'
  },
  {
    path: '/processo-ato-apuracao/:id/:ano',
    component: ApuracaoAnualDataPage,
    title: 'Apuração Anual',
    role: Roles.processo_update.name,
    icon: 'cash-register'
  },
  {
    path: '/processo-ato-apuracao/:id/:ano/:mes',
    component: ApuracaoMensalDataPage,
    title: 'Apuração Mensal',
    role: Roles.processo_update.name,
    icon: 'cash-register'
  },
  {
    path: '/enquadramento-legal-apuracao/:id',
    component: EnquadramentoLegalApuracoesComponent,
    title: 'Apuração Mensal',
    role: Roles.processo_update.name,
    icon: 'cash-register'
  },
  {
    path: '/solicitacao-baixa-redesim',
    component: SolicitacaoBaixaRedeSimListPage,
    title: 'Solicitação de baixa',
    role: Roles.processo_update.name,
    icon: 'cash-register'
  },
  {
    path: '/apuracao-nota-fiscal/:idApuracao/:id?',
    component: ApuracaoNotaFiscalPage,
    title: 'Apuração Nota Fiscal',
    role: Roles.processo_update.name,
    icon: 'cash-register'
  },
  {
    path: '/apuracao-iss-outra-receita/apuracao/:idApuracao?',
    component: ApuracaoOutrasReceitasListPage,
    title: 'Apuração Outras Receitas',
    role: Roles.processo_update.name,
    icon: 'cash-register'
  }
];

export default processosRoutes;
