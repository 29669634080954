import { DisplayDataGrid, DisplayDataItem, Row } from '@elotech/components';
import React from 'react';

import { Processo } from '../../../types/Processo';

type Props = {
  processo: Processo;
};

export const ProcessoDetailProtocolo: React.FC<Props> = ({ processo }) => {
  return (
    <DisplayDataGrid column={true}>
      <Row>
        <DisplayDataItem md={5} title="Requerente">
          {processo?.requerente?.nome}
        </DisplayDataItem>
        <DisplayDataItem md={5} title="Assunto">
          {`${processo?.assunto?.id || ''} - ${processo?.assunto?.descricao ||
            ''}`}
        </DisplayDataItem>
      </Row>
      <Row>
        <DisplayDataItem md={2} title="CEP">
          {processo?.cep}
        </DisplayDataItem>
        <DisplayDataItem md={4} title="Logradouro">
          {processo?.endereco}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Número Endereço">
          {processo?.numeroEndereco}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Complemento">
          {processo?.complemento}
        </DisplayDataItem>
      </Row>
      <Row>
        <DisplayDataItem md={4} title="Bairro">
          {processo?.bairro}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="Cidade">
          {processo?.cidade}
        </DisplayDataItem>
        <DisplayDataItem md={2} title="UF">
          {processo?.uf}
        </DisplayDataItem>
      </Row>
      <Row>
        <DisplayDataItem md={6} title="Requerimento">
          {processo?.requerimento}
        </DisplayDataItem>
        <DisplayDataItem md={6} title="Observação">
          {processo?.outrosDados}
        </DisplayDataItem>
      </Row>
    </DisplayDataGrid>
  );
};
