import {
  Alert,
  BasicInput,
  Button,
  Chip,
  Container,
  FormikAutocomplete,
  FormikInputCep,
  FormikInputDate,
  FormikInputInteger,
  FormikSelect,
  FormikTextArea,
  Loading,
  Panel,
  Row,
  SectionTitle,
  UrlUtils,
  Wizard,
  Yup,
  buildTouched,
  convertErrorToFormik,
  extractErrorText,
  useShowNotification
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { match } from 'react-router';
import { ObjectSchema } from 'yup';

import { CadastroImobiliario } from '../../common/components/ModalCadastroImobiliario';
import * as SituacaoSolicitacaoProcesso from '../../enum/GeracaoSolicitacaoProcesso';
import AssuntoService from '../../service/AssuntoService';
import AtoProcessoService from '../../service/AtoProcessoService';
import CadastroGeralService from '../../service/CadastroGeralService';
import CepService from '../../service/CepService';
import PessoaService from '../../service/PessoaService';
import ProcessoService from '../../service/ProcessoService';
import ProtocoloService from '../../service/ProtocoloService';
import TipoLocalInternoService from '../../service/TipoLocalInternoService';
import TipoProcessoAtoService from '../../service/TipoProcessoAtoService';
import TipoProcessoService from '../../service/TipoProcessoService';
import UsuarioService from '../../service/UsuarioService';
import { CadastroGeral } from '../../types/CadastroGeral';
import { CadastroGeralProcessoDTO } from '../../types/CadastroGeralProcessoDTO';
import { GeracaoProcesso } from '../../types/GeracaoProcesso';
import { Pessoa } from '../../types/Pessoa';
import {
  GerarProcessoProtocoloDTO,
  Processo,
  ProcessoGeracaoDTO,
  ProcessoLoteDTO
} from '../../types/Processo';
import { TipoProcesso } from '../../types/TipoProcesso';
import { TipoProcessoAto } from '../../types/TipoProcessoAto';
import { Usuario } from '../../types/Usuario';
import { openGoogleMaps } from '../../utils/utils';
import { LocalField, TipoLocal } from './LocalField';
import ProcessoCadastro from './ProcessoCadastro';
import { dadosGeraisStep, processoValidationSchema } from './validationSchema';

const initialValues: Processo = {
  id: 0
};

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
  historypush: Pick<History, 'push'>;
  location: Location;
};

type ProcessoSteps = 'dadosGerais' | 'cadastrosMobiliarios' | 'protocolo';

export const ProcessoDataPage: React.FC<Props> = ({
  match,
  history,
  historypush,
  location
}) => {
  const [tiposProcessos, setTiposProcessos] = useState<TipoProcesso[]>([]);
  const [tiposAtos, setTiposAtos] = useState<TipoProcessoAto[]>([]);
  const [tipoAutor, setTipoAutor] = useState<TipoLocal>('INTERNO');
  const [tipoReu, setTipoReu] = useState<TipoLocal>('EXTERNO');
  const [tipoProcesso, setTipoProcesso] = useState<TipoProcesso | undefined>();
  const [solicitacaoProcesso, setSolicitacaoProcesso] = useState<
    GeracaoProcesso | undefined
  >();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processo, setProcesso] = useState<Processo>(initialValues);
  const showNotification = useShowNotification();
  const [fromProtocolo, setFromProtocolo] = useState<boolean>(false);
  const [fromGeradorProcesso, setFromGeradorProcesso] = useState<boolean>(
    false
  );

  const [cadastrosProcesso, setCadastrosProcesso] = useState<
    CadastroGeralProcessoDTO[]
  >([]);

  const dataInicial = UrlUtils.getValueFromUrlSearchParams(location, 'dataIni');

  const dataFim = UrlUtils.getValueFromUrlSearchParams(location, 'dataFim');

  const reu = UrlUtils.getValueFromUrlSearchParams(location, 'reu');

  const onChangeSetShowModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (match.params.id !== 'novo') {
      if (location.pathname.includes('protocolo')) {
        setLoading(true);
        setFromProtocolo(true);
        loadTiposProcessos(true);
        ProtocoloService.findUniqueByUUID(match.params.id).then(res => {
          let novoProcesso: Processo = {
            ...initialValues,
            ...res.data
          };
          setProcesso(novoProcesso);
        });
        return;
      }

      if (location.pathname.includes('geracao-processo')) {
        setLoading(true);
        setFromGeradorProcesso(true);
        loadTiposProcessos(false);
        ProcessoService.getProcessoFull(parseInt(match.params.id))
          .then(solicitacao => {
            let data = solicitacao.data;
            if (data.idProtocolo) {
              setFromProtocolo(true);
            }
            setTipoReu('EXTERNO');
            let tipoProcesso: TipoProcesso = {
              id: data.idTipoProcesso,
              tiposProcessoAto: []
            };
            onChangeTipoProcesso(tipoProcesso, {} as FormikProps<Processo>);
            setSolicitacaoProcesso(data);
            setProcesso(data);
          })
          .catch(error => {
            Alert.error(
              { message: 'Não foi possível carregar o registro.' },
              error
            );
          })
          .finally(() => setLoading(false));
        return;
      }

      if (location.pathname.includes('divergencia')) {
        if (reu !== null) {
          setLoading(true);
          loadTiposProcessos(false);
          CadastroGeralService.findById(reu)
            .then((cadastro: any) => {
              let novoProcesso: any = {
                ...initialValues,
                inicioFiscalizacao: dataInicial!,
                fimFiscalizacao: dataFim!,
                reuExterno: cadastro,
                reu: cadastro.pessoa?.nome
              };
              setProcesso(novoProcesso);
            })
            .catch(error => {
              Alert.error(
                { message: 'Não foi possível carregar o registro.' },
                error
              );
            })
            .finally(() => setLoading(false));
        }
        return;
      }

      setLoading(true);
      loadTiposProcessos(false);
      ProcessoService.findById(match.params.id)
        .then(processo => {
          setProcesso(processo.data);
        })
        .catch(error => {
          Alert.error(
            { message: 'Não foi possível carregar o protocolo.' },
            error
          );
        })
        .finally(() => setLoading(false));
      return;
    }

    if (match.params.id === 'novo') {
      if (location.pathname.includes('geracao-processo')) {
        setLoading(true);
        setFromGeradorProcesso(true);
        loadTiposProcessos(false);

        let novoProcesso: any = {
          ...initialValues,
          situacao: 'NOVA'
        };
        setProcesso(novoProcesso);
        setLoading(false);
        return;
      }

      setLoading(true);
      loadTiposProcessos(false);

      let novoProcesso: any = {
        ...initialValues,
        situacao: 'NOVA'
      };
      setProcesso(novoProcesso);
      setLoading(false);
      return;
    }
  }, [match.params.id, dataFim, dataInicial, location.pathname, reu]);

  const loadTiposProcessos = (habilitaDenuncia: boolean) => {
    try {
      setLoading(true);
      let result;

      if (habilitaDenuncia) {
        result = TipoProcessoService.getDenuncias('', { size: -1 });
      } else {
        result = TipoProcessoService.load('', { size: -1 });
      }

      result.then(res => setTiposProcessos(res?.data.content));
    } catch (error) {
      Alert.error({ title: 'Não foi obter os tipos de processo.' }, error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCadastrosProcesso(cadastrosProcesso);
  }, [cadastrosProcesso]);

  const onChangeTipoProcesso = (
    tipoProcesso: TipoProcesso,
    formProps: FormikProps<Processo>
  ) => {
    setTipoProcesso(tipoProcesso);

    if (tipoProcesso && tipoProcesso.id) {
      if (formProps?.setFieldValue && tipoProcesso?.vistoriaEmLote === true) {
        formProps.setFieldValue('reuExterno', undefined, false);
        formProps.setFieldValue('reuInterno', undefined, false);
      }

      TipoProcessoAtoService.loadTiposAtos(tipoProcesso.id)
        .then((result: AxiosResponse<TipoProcessoAto[]>) => {
          setTiposAtos(result.data);
        })
        .catch((error: any) => {
          Alert.error({ title: 'Não foi obter os tipos de atos.' }, error);
        });
    } else {
      setTiposAtos([]);
    }
  };

  const normalized = (entity: Processo) => ({
    ...entity,
    autorInterno: tipoAutor === 'INTERNO' ? entity.autorInterno : undefined,
    autorExterno: tipoAutor === 'EXTERNO' ? entity.autorExterno : undefined,
    reuInterno: tipoReu === 'INTERNO' ? entity.reuInterno : undefined,
    reuExterno: tipoReu === 'EXTERNO' ? entity.reuExterno : undefined,
    solicitacaoProcesso
  });

  const confirmSave = async (entity: Processo) => {
    setLoading(true);
    let processo = entity;
    const existeProcessoParaReu = await ProcessoService.verificarExisteProcessoParaReu(
      {
        idTipoProcesso: processo.idTipoProcesso,
        fimFiscalizacao: processo.fimFiscalizacao,
        inicioFiscalizacao: processo.inicioFiscalizacao,
        reuExterno: tipoReu === 'EXTERNO' ? entity.reuExterno : undefined,
        reuInterno: tipoReu === 'INTERNO' ? entity.reuInterno : undefined,
        idReuGeracaoProcesso: processo.idReuGeracaoProcesso
      } as ProcessoGeracaoDTO
    );
    setLoading(false);
    if (existeProcessoParaReu.data.exitsProcesso) {
      return await Alert.question({
        title: 'Deseja continuar?',
        text: existeProcessoParaReu.data.mensagem
      }).then((result: any) => result.value);
    }
    return true;
  };

  const verificaAto = (entity: any) => {
    setLoading(true);
    if (entity.reuExterno !== undefined) {
      AtoProcessoService.existTipoProcessoAtoCadastro(
        +entity.reuExterno.id,
        entity.idTipoProcessoAto
      )
        .then(response => {
          setLoading(false);
          if (response.data.id !== 0) {
            Alert.question({
              title: `Atenção! Já existe um ato semelhante para este cadastro vinculado ao processo ${response.data.processo}/${response.data.exercicio}, Deseja criar um novo processo mesmo assim?`
            }).then((result: any) => {
              if (result.value) {
                saveDefaultOrProtocolo(result);
              }
            });
          } else {
            saveDefaultOrProtocolo(entity);
          }
        })
        .catch((error: any) =>
          Alert.error(
            {
              title:
                'Ocorreu um erro ao verificar existência de processo do mesmo cadastro'
            },
            error
          )
        );
    } else {
      saveDefaultOrProtocolo(entity);
    }
  };

  const save = (entity: Processo) => {
    setLoading(true);
    ProcessoService.save(normalized(entity))
      .then((response: { data: { id: any } }) => {
        showNotification({
          level: 'success',
          message: 'Registro salvo com sucesso.'
        });
        history.replace(`/processo/${response.data.id}/visualizar`);
        return undefined; // Evita o redirecionamento pelo DataPage
      })
      .catch((error: any) => {
        Alert.error(
          {
            title: 'Ocorreu um erro ao salvar processo'
          },
          error
        );
        setLoading(false);
      });
  };

  const saveFromProtocolo = async (entity: GerarProcessoProtocoloDTO) => {
    ProcessoService.saveProcessoProtocolo(entity)
      .then(res => {
        showNotification({
          level: 'success',
          message: 'Protocolo salvo com sucesso.'
        });
        history.replace(`/processo/${res.data}/visualizar`);
        return undefined;
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Ocorreu um erro ao salvar processo'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const saveProcesso = async (entity: GerarProcessoProtocoloDTO) => {
    ProcessoService.saveProcesso(entity)
      .then(res => {
        showNotification({
          level: 'success',
          message: 'Protocolo salvo com sucesso.'
        });
        history.replace(`/processo/${res.data}/visualizar`);
        return undefined;
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Ocorreu um erro ao salvar processo'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const saveAndRedirect: any = async (entity: any) => {
    if (tipoProcesso?.vistoriaEmLote) {
      saveVistoriaEmLote(entity);
      return undefined;
    }

    if (
      !!tipoProcesso?.reuNaoObrigatorio &&
      !entity.reuExterno &&
      !entity.reuInterno
    ) {
      await Alert.question({
        title: 'Deseja continuar?',
        text: 'Réu não preenchido. O processo será salvo sem ato.'
      }).then((result: any) => {
        if (!!result.value) {
          entity.atos = [];
        }
        saveDefaultOrProtocolo(entity);
      });
    } else {
      const confirm = await confirmSave(entity);
      if (confirm) {
        verificaAto(entity);
      }
    }
  };

  const saveDefaultOrProtocolo = (entity: any) => {
    if (fromProtocolo) {
      saveFromProtocolo(entity);
      return;
    }

    if (fromGeradorProcesso) {
      saveProcesso(entity);
      return;
    }

    save(normalized(entity));
  };

  const saveVistoriaEmLote = (entity: any) => {
    if (
      !cadastrosProcesso.some(
        cadastroProcesso => !!cadastroProcesso.selecionado
      )
    ) {
      Alert.error({
        title: 'Selecione ao menos um cadastro para gerar o processo!'
      });
      return;
    }

    const cadastrosSelecionados = cadastrosProcesso.filter(
      cadastroProcesso => !!cadastroProcesso.selecionado
    );

    setLoading(true);
    ProcessoService.saveProcessoLote({
      processo: normalized(entity),
      empresas: cadastrosSelecionados
    } as ProcessoLoteDTO)
      .then(() => {
        showNotification({
          level: 'success',
          message: 'Processos em lote realizado com sucesso!'
        });
        history.replace('/processo');
        return undefined;
      })
      .catch(error => {
        Alert.error(
          { title: 'Ocorreu um erro ao criar os processos em lote!' },
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const previewGoogleMaps = (localizacao: string) => {
    if (localizacao === undefined || localizacao.trim() === '') {
      Alert.error({ title: 'É necessário preencher a Localização' });
      return;
    }
    openGoogleMaps(localizacao);
  };

  const fieldsSteps: { [key in ProcessoSteps]: string[] } = {
    dadosGerais: Object.keys(dadosGeraisStep(tipoProcesso, tipoAutor, tipoReu)),
    cadastrosMobiliarios: Object.keys({}),
    protocolo: Object.keys({})
  };

  const validators: { [key in ProcessoSteps]: ObjectSchema<any> } = {
    dadosGerais: Yup.object().shape(
      dadosGeraisStep(tipoProcesso, tipoAutor, tipoReu)
    ),
    cadastrosMobiliarios: Yup.object().shape({}),
    protocolo: Yup.object().shape({})
  };

  const stepHasError = (
    stepId: ProcessoSteps,
    formikProps: FormikProps<Processo>
  ): boolean => extractErrorText(formikProps, fieldsSteps[stepId]) !== '';

  const validateStep = async (
    stepId: ProcessoSteps,
    formProps: FormikProps<Processo>
  ): Promise<boolean> => {
    const { values, errors, touched, setErrors, setTouched } = formProps;
    return validators[stepId]
      .validate(values, { abortEarly: false })
      .then(() => {
        const clearErrorValues = fieldsSteps[stepId].reduce(
          (acc, current) => ({ ...acc, [current]: undefined }),
          {}
        );

        setErrors({ ...errors, ...clearErrorValues });

        return false;
      })
      .catch(error => {
        const errors = convertErrorToFormik(error);
        setErrors({ ...errors, ...errors });
        setTouched({
          ...touched,
          ...buildTouched(fieldsSteps[stepId])
        });
        return true;
      });
  };

  const onBeforeChange = (formikProps: FormikProps<Processo>) => async (
    oldStepData: any,
    newStepData: any
  ) => {
    const stepHasErrors = await validateStep(oldStepData.stepId, formikProps);

    return {
      oldStepData: { ...oldStepData, valid: !stepHasErrors },
      newStepData
    };
  };

  const onBlurCep = (formProps: any) => (event: any) => {
    const cep = event.target.value.replace(/[^0-9]/gi, '');
    if (cep.length >= 8) {
      setLoading(true);
      CepService.getCep(cep).then(({ data }) => {
        for (const key in data) {
          formProps.setFieldValue(key, data[key]);
        }
        setLoading(false);
      });
    }
  };

  const onCreateReu = async (nome: string) => {
    if (!fromProtocolo) {
      Alert.error({ title: 'Ação habilitada para importação de protocolo.' });
      return Promise.reject();
    }
    return CadastroGeralService.onCreateCadastroGeral({ nome })
      .then(res => Promise.resolve(res))
      .catch(err => {
        Alert.error({ title: 'Não foi possível carregar o cadastro.' }, err);
        return Promise.reject();
      });
  };

  return (
    <>
      <Container breadcrumb>
        <Loading loading={loading} />
        <Panel isTable>
          <Formik
            enableReinitialize
            initialValues={processo}
            onSubmit={saveAndRedirect}
            validationSchema={processoValidationSchema(
              tipoProcesso,
              tipoAutor,
              tipoReu,
              fromProtocolo,
              fromGeradorProcesso
            )}
            render={(formProps: FormikProps<Processo>) => (
              <Wizard
                onFinish={formProps.submitForm}
                beforeChange={onBeforeChange(formProps)}
                finishButtonOnlyOnLastStep
              >
                <Wizard.Step
                  stepId="dadosGerais"
                  label="Dados Gerais"
                  icon="far fa-list"
                  showPreviousButton={false}
                  valid={!stepHasError('dadosGerais', formProps)}
                  errorMessage={extractErrorText(
                    formProps,
                    fieldsSteps['dadosGerais']
                  )}
                >
                  <>
                    {(fromGeradorProcesso || fromProtocolo) && (
                      <>
                        <SectionTitle children="Dados da Solicitação" />
                        <Row>
                          <FormikAutocomplete<CadastroGeral>
                            data-test-id={`reu`}
                            name={'reu'}
                            label={'Réu'}
                            onSearch={
                              fromGeradorProcesso
                                ? CadastroGeralService.autoComplete
                                : CadastroGeralService.autoCompleteAvulso
                            }
                            getOptionLabel={value =>
                              value.pessoa
                                ? `${value.pessoa?.cnpjCpf || ''} - ${value
                                    .pessoa?.nome ||
                                    ''} - ${value.tipoCadastro || ''}`
                                : ''
                            }
                            size={6}
                            newValueLabel={'Adicionar novo réu'}
                            onCreateValue={texto => onCreateReu(texto)}
                          />
                          <BasicInput
                            name="situacao"
                            label="Situação"
                            render={props => (
                              <>
                                <Chip
                                  inline={true}
                                  value={SituacaoSolicitacaoProcesso.getDescricaoGeracao(
                                    props.field.value
                                  )}
                                />
                                {fromProtocolo && !!processo.denunciado && (
                                  <Chip
                                    inline={true}
                                    value={`Denunciado: ${props.form.values['denunciado']}`}
                                  />
                                )}
                              </>
                            )}
                          />
                        </Row>
                        <Row>
                          <FormikAutocomplete<Usuario>
                            data-test-id={`fiscalResponsavel`}
                            name={'fiscalResponsavel'}
                            label={'Fiscal Responsável'}
                            onSearch={UsuarioService.usuarioAutoComplete}
                            getOptionLabel={value => `${value.nome}`}
                            getOptionValue={value => value.id}
                            size={6}
                          />
                        </Row>
                        <SectionTitle children="Dados do Processo" />
                      </>
                    )}
                    <Row>
                      <FormikInputDate
                        label="Início da Fiscalização"
                        name="inicioFiscalizacao"
                        size={3}
                      />
                      <FormikInputDate
                        label="Fim da Fiscalização"
                        name="fimFiscalizacao"
                        size={3}
                      />
                      {!processo.idProtocolo && (
                        <FormikAutocomplete
                          data-test-id={`auto-complete-denunciante`}
                          name={`denunciante`}
                          label={`Denunciante`}
                          onSearch={PessoaService.search}
                          getOptionLabel={(value: Pessoa) =>
                            `${value?.cnpjCpf} - ${value.nome}`
                          }
                          size={6}
                        />
                      )}
                    </Row>
                    <Row>
                      <FormikSelect<TipoProcesso>
                        name="idTipoProcesso"
                        label="Tipo do Processo"
                        options={tiposProcessos}
                        getOptionLabel={(option: { descricao?: string }) =>
                          option.descricao
                        }
                        getOptionValue={(option: { id: number }) => option.id}
                        size={6}
                        fast={false}
                        onSelect={(value: TipoProcesso) =>
                          onChangeTipoProcesso(value, formProps)
                        }
                      />
                      <FormikSelect<TipoProcessoAto>
                        name="idTipoProcessoAto"
                        label="Tipo do Ato"
                        options={tiposAtos}
                        getOptionLabel={(option: {
                          tipoAto?: { descricao?: string };
                        }) => option.tipoAto?.descricao}
                        getOptionValue={(option: { id: number }) => option.id}
                        size={6}
                        fast={false}
                      />
                    </Row>
                    <Row>
                      {(tipoProcesso?.exigeNumeroProtocolo ||
                        fromProtocolo ||
                        fromGeradorProcesso) && (
                        <FormikInputInteger
                          label="Número do Protocolo"
                          name="numeroProtocolo"
                          size={3}
                          maxLength={8}
                          fast={false}
                        />
                      )}
                      {tipoProcesso?.exigeNumeroProtocolo && (
                        <>
                          <FormikInputInteger
                            label="Ano do Protocolo"
                            name="anoProtocolo"
                            size={3}
                            maxLength={4}
                            fast={false}
                          />
                        </>
                      )}
                    </Row>
                    <Row>
                      <LocalField
                        namePrefix="autor"
                        labelPrefix="Autor"
                        openModal={onChangeSetShowModal}
                        onSearchCadastroGeral={
                          CadastroGeralService.autoComplete
                        }
                        onSearchTipoLocal={TipoLocalInternoService.load}
                        size={6}
                        tipo={tipoAutor}
                        onSetTipo={(tipo: any) => setTipoAutor(tipo)}
                      />
                    </Row>
                    <Row>
                      <FormikTextArea
                        name="observacao"
                        label="Observação"
                        size={12}
                        maxLength={4000}
                      />
                    </Row>
                    {!tipoProcesso?.vistoriaEmLote &&
                      !tipoProcesso?.denuncia &&
                      !fromProtocolo && (
                        <Row>
                          <LocalField
                            namePrefix="reu"
                            labelPrefix="Réu"
                            openModal={onChangeSetShowModal}
                            permiteImobiliario={
                              tipoProcesso?.exigeCadastroImobiliario
                            }
                            onSearchCadastroGeral={
                              CadastroGeralService.autoCompleteReuExterno
                            }
                            onSearchTipoLocal={TipoLocalInternoService.load}
                            size={6}
                            tipo={tipoReu}
                            onSetTipo={(tipo: any) => setTipoReu(tipo)}
                          />
                        </Row>
                      )}
                    {tipoProcesso?.exigeEndereco && (
                      <Row>
                        <BasicInput
                          hint={`Neste campo deve ser informado o endereço completo, conforme exemplo, “Rua Tupã, 1643 - Recanto dos Magnatas, Maringá - PR, 87060-510” caso não tenha todos os dados é possível informar a longitude e latitude, conforme exemplo,"-23.4509116,-51.9481166"`}
                          size={6}
                          label="Localização"
                          name="localizacao"
                        />
                        <div
                          className="form-group col-md-1"
                          style={{ paddingLeft: 0 }}
                        >
                          <label className="label"></label>
                          <Button
                            data-testid="botao-localizacao"
                            iconPosition="left"
                            onClick={() =>
                              previewGoogleMaps(formProps.values.localizacao!)
                            }
                          >
                            <i className="fas fa-map-marker-alt"></i>
                          </Button>
                        </div>
                      </Row>
                    )}
                    {showModal && (
                      <CadastroImobiliario
                        onCloseModal={onChangeSetShowModal}
                        historypush={historypush}
                        formProps={formProps}
                      />
                    )}
                  </>
                </Wizard.Step>

                <Wizard.Step
                  stepId="protocolo"
                  label="Dados do Protocolo"
                  icon="far fa-file-invoice"
                  visible={fromProtocolo}
                >
                  <Row>
                    <FormikAutocomplete
                      size={6}
                      fast={false}
                      name="requerente"
                      label="Requerente"
                      newValueLabel="Adicionar Requerente"
                      onSearch={PessoaService.search}
                      getOptionLabel={(value: any) =>
                        `${value.cnpjCpf || ''} - ${value.nome || ''}`
                      }
                      disabled={fromProtocolo}
                    />
                    <FormikAutocomplete
                      size={6}
                      fast={false}
                      name="assunto"
                      label="Assunto"
                      onSearch={
                        AssuntoService.findAllAssuntosInternoAutocomplete
                      }
                      getOptionLabel={(v: any) =>
                        `${v.idProtocolo} - ${v.descricao}`
                      }
                      disabled={true}
                    />
                  </Row>
                  <SectionTitle>Endereço Complementar</SectionTitle>
                  <Row>
                    <FormikInputCep
                      size={2}
                      name="cep"
                      label="CEP"
                      maxLength={8}
                      onChange={onBlurCep(formProps)}
                      hint="Digite o CEP do endereço para realizar a busca. (Somente números)"
                      disabled={fromProtocolo}
                    />
                    <BasicInput
                      size={4}
                      name="logradouro"
                      label="Logradouro"
                      maxLength={100}
                      disabled={fromProtocolo}
                    />
                    <BasicInput
                      size={2}
                      name="numeroEndereco"
                      label="Número"
                      maxLength={10}
                      disabled={fromProtocolo}
                    />
                    <BasicInput
                      size={4}
                      name="complemento"
                      label="Complemento"
                      maxLength={50}
                      disabled={fromProtocolo}
                    />
                  </Row>
                  <Row>
                    <BasicInput
                      size={6}
                      name="bairro"
                      label="Bairro"
                      maxLength={40}
                      disabled={fromProtocolo}
                    />
                    <BasicInput
                      size={4}
                      name="cidade"
                      label="Cidade"
                      maxLength={40}
                      disabled={fromProtocolo}
                    />
                    <BasicInput
                      size={2}
                      name="uf"
                      label="UF"
                      maxLength={2}
                      disabled={fromProtocolo}
                    />
                  </Row>
                  <SectionTitle>Observações</SectionTitle>
                  <Row>
                    <FormikTextArea
                      size={6}
                      rows={4}
                      name="requerimento"
                      label="Requerimento"
                      disabled={fromProtocolo}
                    />
                    <FormikTextArea
                      size={6}
                      rows={4}
                      name="outrosDados"
                      label="Observação"
                      disabled={fromProtocolo}
                    />
                  </Row>
                </Wizard.Step>

                <Wizard.Step
                  stepId="cadastrosMobiliarios"
                  label="Cadastros"
                  icon="far fa-file-alt"
                  visible={
                    (fromGeradorProcesso || fromProtocolo) &&
                    tipoProcesso?.vistoriaEmLote === true
                  }
                >
                  <ProcessoCadastro
                    cadastrosProcesso={cadastrosProcesso}
                    setCadastrosProcesso={setCadastrosProcesso}
                  />
                </Wizard.Step>
              </Wizard>
            )}
          />
        </Panel>
      </Container>
    </>
  );
};
