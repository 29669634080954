import { EnumValue } from '../types/EnumValue';

export const SituacaoProcessoProtocolo: EnumValue[] = [
  { codigo: 'NOVA', descricao: 'Nova' },
  { codigo: 'EM_ANDAMENTO', descricao: 'Em Andamento' },
  { codigo: 'ENCERRADA', descricao: 'Encerrada' }
];

export const getDescricaoSituacaoProcessoProtocolo = (situacao: string) => {
  const found = SituacaoProcessoProtocolo.find(
    item => item.codigo === situacao
  );
  return found ? found.descricao : '';
};
