import axios from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';

export const BASE_URL: string = `${CONTEXT_PATH}/assunto/`;

const findAllAssuntosInternoAutocomplete = (search: string) => {
  return axios.get(BASE_URL);
};

export default {
  findAllAssuntosInternoAutocomplete
};
